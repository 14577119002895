import PropTypes from 'prop-types';
import Router from 'next/router';
import {useSession} from 'next-auth/client';
import {UserQueryProvider} from 'components/providers/UserQueryProvider';
import {
    LAST_KNOWN_ROUTE_KEY,
    LOGOUT_ROUTE,
    useLastKnownRoute,
} from 'components/hooks/useLastKnownRoute.ts';

import {getLastKnownRoute} from 'components/util/getLastKnownRoute.ts';
import {LOGIN_URL} from 'components/util/routeConstants';

function AuthenticationRouter({children}) {
    const [session, loading] = useSession();
    useLastKnownRoute(session);
    if (loading) return null;

    if (!session) {
        const path = Router.asPath;
        const lastKnownRoute = getLastKnownRoute();

        if (lastKnownRoute !== LOGOUT_ROUTE) {
            window.localStorage.setItem(
                LAST_KNOWN_ROUTE_KEY,
                JSON.stringify(path)
            );
        }

        Router.push(LOGIN_URL);
        return null;
    }

    return (
        <UserQueryProvider userId={session.user.userId}>
            {({user}) => {
                return children({user});
            }}
        </UserQueryProvider>
    );
}

AuthenticationRouter.propTypes = {
    children: PropTypes.any,
};

export {AuthenticationRouter};
